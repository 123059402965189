import { Component, SyntheticEvent } from 'react';
import { matchPath } from 'react-router';
import classNames from 'classnames';
import queryString from 'query-string';
// @ts-ignore
import Cookies from 'universal-cookie';
import { DomainOptions, OptionsResponseDto } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Banner, FormFeedback, Spinner } from '@just-ai/just-ui/dist';

import { t } from 'localization';
import localize from 'localization';
import { getDomainData, getUserLanguage, isAxiosError, langToUpperCase } from 'pipes/functions';
import { axios } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import Recaptcha, { isCaptchaReady } from 'components/Recaptcha';
import chatSupportController from 'helpers/chatSupportController';
import aimychat from 'images/aimychat.svg';
import aimylogic from 'images/aimylogic.svg';
import aimyvoice from 'images/aimyvoice.svg';
import caila from 'images/caila.svg';
import cloud from 'images/cloud.svg';
import copilot from 'images/copilot.png';
import generalLogo from 'images/general-logo.svg';
import jaicf from 'images/jaicf.svg';
import jaicp from 'images/jaicp.svg';
import tovieAgent from 'images/tovie/t-agent.svg';
import tovieAgentText from 'images/tovie/t-agent-text.svg';
import tovieCloud from 'images/tovie/t-cloud.svg';
import tovieCloudText from 'images/tovie/t-cloud-text.svg';
import tovieCopilot from 'images/tovie/t-copilot.svg';
import tovieCopilotText from 'images/tovie/t-copilot-text.svg';
import tovieDS from 'images/tovie/t-dialogstudio.svg';
import tovieDsText from 'images/tovie/t-ds-text.svg';
import tovieGeneralLogo from 'images/tovie/t-general-logo.svg';
import toviePlatform from 'images/tovie/t-platform.svg';
import toviePlatformText from 'images/tovie/t-platform-text.svg';
import tovieVoice from 'images/tovie/t-voice.svg';
import tovieVoiceText from 'images/tovie/t-voice-text.svg';
import { utmKeeperService } from 'service/UtmKeeper';

import { basepageLocalization } from './localization/basepage.loc';
import BasePageBackground from './BasePageBackground';

import './index.scss';

localize.addTranslations(basepageLocalization);

const cookies = new Cookies();

const LOGOS = [caila, jaicp, jaicf, aimylogic, aimyvoice, aimychat];

export type Error = {
  args?: {
    path: string;
    [key: string]: string | number;
  } | null;
  error?: string;
  uuid?: string;
};

export type DomainToLoginItem = {
  host: string;
  token: string;
};

export interface BaseStateTypes {
  gRecaptchaResponse?: any;
  fetching: boolean;
  gRecaptchaError?: boolean;
  errors: Error[] | [];
  isLoginPage?: boolean;
  loaded?: boolean;
}

export interface BasePropTypes {
  location: {
    search: string;
    pathname: string;
  };
}

export class BasePage<PropTypes extends BasePropTypes, StateTypes extends BaseStateTypes> extends Component<
  PropTypes,
  StateTypes
> {
  name: string = '';
  static contextType = AppContext;
  recaptchaInstance: any = null;
  supportTimer: any;
  captchaPromiseResolve: any = null;
  captchaReadyInterval: NodeJS.Timer | null = null;

  AccountsadminService = new AccountsadminService(axios);

  renderLogo = () => {
    const { theme, appConfig } = this.context;
    const euroInstance = appConfig?.euroInstance;
    let cailaDomain = appConfig?.domains?.caila?.domain;

    switch (theme) {
      case 'aimyvoice':
        return (
          <div className='app-logo'>
            <img src={euroInstance ? tovieVoice : aimyvoice} alt='logo' />
          </div>
        );
      case 'caila':
      case 'immers': {
        const logo = (
          <div className='app-logo'>
            <img src={euroInstance ? tovieDS : caila} alt='logo' />
          </div>
        );
        return cailaDomain ? (
          <a
            className='app-link'
            href={`${window.location.protocol}//${cailaDomain}`}
            style={{ textDecoration: 'none' }}
          >
            {logo}
          </a>
        ) : (
          logo
        );
      }
      case 'jaicp':
        return (
          <div className='app-logo'>
            <img src={euroInstance ? toviePlatform : jaicp} alt='logo' />
          </div>
        );
      case 'aimylogic':
        return (
          <div className='app-logo'>
            <img src={euroInstance ? tovieDS : aimylogic} alt='logo' />
          </div>
        );
      case 'aimychat':
        return (
          <div className='app-logo'>
            <img src={euroInstance ? tovieAgent : aimychat} alt='logo' />
          </div>
        );
      case 'copilot':
        return (
          <div className='app-logo'>
            <img
              src={euroInstance ? tovieCopilot : copilot}
              alt='logo'
              style={euroInstance ? { maxWidth: 170 } : undefined}
            />
          </div>
        );
      case 'cloud':
        return (
          <div className='app-logo'>
            <img src={euroInstance ? tovieCloud : cloud} alt='logo' />
          </div>
        );
      default:
        return (
          <div className='app-logo'>
            <img src={euroInstance ? tovieGeneralLogo : generalLogo} alt='logo' />
          </div>
        );
    }
  };

  renderTitle = () => {
    const { theme, domainOptions, appConfig } = this.context;
    const euroInstance = appConfig?.euroInstance;
    let cailaDomain = appConfig?.domains?.caila?.domain;

    switch (theme) {
      case 'aimyvoice':
        return (
          <div className='app-title'>
            {euroInstance ? <img src={tovieVoiceText} alt='description-logo' /> : <h1>Aimyvoice</h1>}
            {!euroInstance && (
              <h2>
                Part of Just AI <br />
                Conversational Cloud
              </h2>
            )}
          </div>
        );
      case 'immers': {
        const title = !euroInstance ? (
          <div className='app-title_immers'>
            <h1>Caila</h1>
            <h2>by Just AI</h2>
          </div>
        ) : null;
        const immersInfo = !euroInstance ? (
          <div className='immers-info'>
            <p className='immers-info__text'>
              <b>Caila</b> – платформа для хостинга, обучения и тестирования ML-моделей.
              <br /> Caila разработана компанией Just AI и интегрирована с платформой Immers.cloud.
            </p>
            <p className='immers-info__text_sm'>
              Для использования сервиса <b>caila.immers.cloud</b> необходимо создать учетную запись в Caila. Пожалуйста,
              зарегистрируйтесь!
            </p>
            <div className='flex-row gap-48 margin-top-48'>
              <a href='https://immers.cloud/' target='_blank' rel='noreferrer noopener'>
                Immers.cloud
              </a>
              <a href='https://just-ai.com/' target='_blank' rel='noreferrer noopener'>
                just-ai.com
              </a>
            </div>
          </div>
        ) : null;

        return cailaDomain ? (
          <>
            <a
              className='app-link'
              href={`${window.location.protocol}//${cailaDomain}`}
              style={{ textDecoration: 'none' }}
            >
              {title}
            </a>
            {immersInfo}
          </>
        ) : (
          <>
            {title}
            {immersInfo}
          </>
        );
      }
      case 'caila': {
        const title = !euroInstance ? (
          <div className='app-title'>
            <h1>Caila</h1>
            <h2>
              Part of Just AI <br />
              Conversational Cloud
            </h2>
          </div>
        ) : null;
        return cailaDomain ? (
          <a
            className='app-link'
            href={`${window.location.protocol}//${cailaDomain}`}
            style={{ textDecoration: 'none' }}
          >
            {title}
          </a>
        ) : (
          title
        );
      }
      case 'aimylogic':
        return (
          <div className='app-title'>
            {euroInstance ? <img src={tovieDsText} alt='description-logo' /> : <h1>Aimylogic</h1>}
            {!euroInstance && (
              <h2>
                Part of Just AI <br />
                Conversational Cloud
              </h2>
            )}
          </div>
        );
      case 'jaicp':
        return (
          <div className='app-title'>
            {euroInstance ? <img src={toviePlatformText} alt='description-logo' /> : <h1>JAICP</h1>}
            {!euroInstance && (
              <h2>
                Part of Just AI <br />
                Conversational Cloud
              </h2>
            )}
          </div>
        );
      case 'aimychat':
        return (
          <div className='app-title'>
            {euroInstance ? <img src={tovieAgentText} alt='description-logo' /> : <h1>Aimychat</h1>}
            {!euroInstance && (
              <h2>
                Part of Just AI <br />
                Conversational Cloud
              </h2>
            )}
          </div>
        );
      case 'copilot':
        return (
          <div className='app-title copilot'>
            {euroInstance ? (
              <img src={tovieCopilotText} alt='description-logo' />
            ) : (
              <>
                <h1>Jay Copilot</h1>
                <h2>Just AI</h2>
              </>
            )}
          </div>
        );
      case 'cloud':
        return (
          <div className='app-title'>
            <div className='cloud-title'>
              {euroInstance ? <img src={tovieCloudText} alt='description-logo' /> : <h1>Just AI</h1>}
              {!euroInstance && (
                <h2>
                  Conversational <br />
                  Cloud
                </h2>
              )}
            </div>
          </div>
        );
      default:
        return (
          <div className='app-title general-logo'>
            <h1>{domainOptions?.appTitle || ''}</h1>
            <h2>{euroInstance ? 'Tovie AI' : 'Just AI'}</h2>
          </div>
        );
    }
  };

  renderInputs = () => {};

  renderButtons = () => {};

  submit = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  renderBody = () => {
    return (
      <form onSubmit={this.submit}>
        {this.renderInputs()}
        {this.renderButtons()}
      </form>
    );
  };

  renderHead = () => {};

  renderFooter = () => {};

  renderCommonErrors = (): JSX.Element | void | false | unknown => {
    const { errors } = this.state;
    const commonErrors: Error[] = errors.filter(
      (error: Error) => !error?.args?.path || error?.args?.path === '$.redirectUrl'
    );
    return (
      commonErrors?.length > 0 && (
        <div className='base-page_formarea-errors'>
          <Banner
            type='danger'
            withIcon
            BannerText={() => (
              <>
                {commonErrors.map(error => (
                  <div key={`commonError_${error.error}`}>{t(`BasePage:BE-error ${error.error}`, error.uuid)}</div>
                ))}
              </>
            )}
          />
        </div>
      )
    );
  };

  renderArea = () => {
    const { fetching } = this.state;
    const {
      location: { pathname },
    } = this.props;
    return (
      <div
        data-test-id={this.name ? `${this.name}Page` : ''}
        className={classNames('base-page_formarea', {
          'phone-verification-area': pathname.includes('/c/phone-verification'),
        })}
      >
        {fetching && <Spinner size='4x' />}
        {this.renderHead()}
        {this.renderCommonErrors()}
        {this.renderBody()}
        {this.renderFooter()}
      </div>
    );
  };

  renderCopyright = () => {
    const {
      domainOptions,
      appConfig: { euroInstance },
    } = this.context;

    const partOfCC = domainOptions?.partOfConversationalCloud;

    return (
      <div className='base-page_copyright'>
        <p>
          <small data-test-id='BasePage.Copyright'>
            {partOfCC &&
              (euroInstance
                ? `Tovie AI Cloud © ${new Date().getFullYear()} Tovie AI`
                : `Just AI Conversational Cloud © ${new Date().getFullYear()} Just AI`)}
            {!partOfCC && `© ${euroInstance ? 'Tovie' : 'Just'} AI ${new Date().getFullYear()}`}
          </small>
        </p>
      </div>
    );
  };

  renderLogos = () => {
    const {
      theme,
      domainOptions,
      appConfig: { euroInstance },
    } = this.context;

    if (!domainOptions?.partOfConversationalCloud) return null;
    if (theme === 'immers') return null;
    return (
      <div className='base-page_logos'>
        {!euroInstance &&
          LOGOS.map((logo, index) => (
            <div key={`logo_${index}`} className='base-page_logos-logo'>
              <img src={logo} alt='' />
            </div>
          ))}
      </div>
    );
  };

  renderLeftBlock = () => {
    const { domainOptions } = this.context;
    const partOfCC = domainOptions?.partOfConversationalCloud || false;
    return (
      <div className={classNames('base-page_left-block', { 'not-part-of-cc': !partOfCC })}>
        <div className='base-page_left-block-app-info-wrap'>
          {this.renderLogo()}
          {this.renderTitle()}
        </div>
        {this.renderLogos()}
      </div>
    );
  };

  verifyCallback = (response: any) => {
    this.setState(
      {
        gRecaptchaResponse: response,
        gRecaptchaError: false,
      },
      () => {
        if (this.captchaPromiseResolve) {
          this.captchaPromiseResolve();
          this.captchaPromiseResolve = null;
        }
      }
    );
  };

  executeCaptcha = async () => {
    const { gRecaptchaResponse } = this.state;

    if (cookies.get('CAPTCHA_BYPASS_TOKEN')) return;

    await new Promise(res => {
      this.captchaReadyInterval = setInterval(() => {
        if (isCaptchaReady()) {
          res(null);
          this.captchaReadyInterval && clearInterval(this.captchaReadyInterval);
        }
      }, 1000);
    });

    if (this.recaptchaInstance && !gRecaptchaResponse) {
      await this.recaptchaInstance.execute();
      await new Promise(res => {
        this.captchaPromiseResolve = res;
      });
    }
  };

  resetCaptcha = () => {
    if (Boolean(this.recaptchaInstance)) {
      this.recaptchaInstance?.reset();
      this.setState({ gRecaptchaResponse: null });
    }
  };

  renderCaptcha = (): JSX.Element | void => {
    const { appConfig } = this.context;

    return (
      appConfig?.captcha?.enabled &&
      appConfig?.captcha?.siteKey && (
        <Recaptcha
          data-test-id='BasePage.recaptcha'
          ref={(instance: any) => (this.recaptchaInstance = instance)}
          sitekey={appConfig?.captcha?.siteKey}
          className='captcha'
          size='invisible'
          verifyCallback={this.verifyCallback}
          expiredCallback={this.resetCaptcha}
          hl={getUserLanguage().substr(0, 2).toUpperCase()}
        />
      )
    );
  };

  supportAddOnMessageListener = () => {
    try {
      chatSupportController.init();
      chatSupportController.show();
    } catch (e) {
      this.supportTimer = setTimeout(this.supportAddOnMessageListener, 500);
    }
  };

  renderFieldError = (name?: string) => {
    const { errors } = this.state;

    const error: Error | undefined = errors.find((error: Error) => error?.args?.path?.includes(name as string));

    return error ? (
      <FormFeedback tag='div' valid={false}>
        {t(
          error['error']
            ? `BasePage:BE-error ${error['error']} ${error['args']?.['path']}`
            : 'Register: required field',
          t(`Register: password ${error['args']?.['strength']}`)
        )}
      </FormFeedback>
    ) : null;
  };

  externalSsoLogin = async (email: string) => {
    try {
      const { available, oauth2Provider } = await this.AccountsadminService.checkEmailForSso({
        email: email.trim() || '',
      });

      if (!available || !oauth2Provider) {
        this.setState({
          errors: [{ args: { path: 'email' }, error: 'external.sso.not.available' }],
          fetching: false,
        });
        return;
      }

      this.loginWithProvider(oauth2Provider);
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];

        this.setState({
          errors: errors,
        });
      }
    }
  };

  loginWithProvider = (provider: string, invitationEmail?: string, invitationAccountId?: string) => {
    const {
      location: { search },
    } = this.props;

    const { appConfig, language } = this.context;

    const { redirectUrl, product } = getDomainData(search, appConfig.domains);
    const { redirectUrl: queryRedirectUrl } = queryString.parse(search.replace('?', ''));

    if (!product) return;

    localStorage.CLOUD_REDIRECT_URL = queryRedirectUrl || redirectUrl;

    const url = new URL(`${window.location.origin}/api/accountsadmin/public/authorization/oauth2/${provider}`);
    url.searchParams.set('product', product);
    url.searchParams.set('language', langToUpperCase(language));
    invitationEmail && url.searchParams.set('invitationEmail', invitationEmail);
    invitationAccountId && url.searchParams.set('invitationAccountId', invitationAccountId);
    const utmMarks = utmKeeperService.getUtmMarks();

    if (utmMarks) {
      Object.entries(utmMarks).forEach(([key, value]) => {
        url.searchParams.set(key, value);
      });
    }

    window.location.href = url.toString();
  };

  checkDomainForDirectSso = () => {
    const { appConfig } = this.context;
    const { domains } = appConfig;

    const currentDomainData = Object.values(domains as OptionsResponseDto['domains']).find(
      (value: DomainOptions) => value.domain === window.location.hostname
    );

    if (!currentDomainData || !currentDomainData?.domainSso?.oauth2Provider) return;

    return currentDomainData.domainSso.oauth2Provider;
  };

  render() {
    const {
      theme,
      appConfig: { euroInstance },
    } = this.context;
    const {
      location: { search },
    } = this.props;

    const queryParams = queryString.parse(search.replace('?', ''));

    const { loaded } = this.state;
    let color;
    switch (theme) {
      case 'aimyvoice':
        color = '#FF9257';
        break;
      case 'caila':
        color = '#FE7876';
        break;
      case 'immers':
        color = '#353374';
        break;
      case 'jaicp':
        color = '#353374';
        break;
      case 'aimylogic':
        color = '#8bbbe499';
        break;
      case 'aimychat':
        color = euroInstance ? '#32AD4E' : '#08A5A799';
        break;
      case 'copilot':
        color = '#353374';
        break;
      default:
        color = '#BA75E099';
    }
    const isPageWithCheck = matchPath(this.props.location.pathname, {
      path: ['/c/login', '/c/register', '/c/register-with-invitation'],
      exact: true,
    });

    const isLoginPage = matchPath(this.props.location.pathname, {
      path: '/c/login',
      exact: true,
    });

    if ((!queryParams.needAuth && isLoginPage) || (isPageWithCheck && this.checkDomainForDirectSso())) return null;

    if (!loaded)
      return (
        <>
          <Spinner size='4x' />
        </>
      );

    return (
      <div className='base-page'>
        {this.renderLeftBlock()}
        {this.renderArea()}
        {this.renderCopyright()}
        {this.renderCaptcha()}
        <BasePageBackground color={color} />
      </div>
    );
  }
}
