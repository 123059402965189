import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import cn from 'classnames';
import { InviteUsersResponse } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import {
  Button,
  Icon,
  Label,
  Spinner,
  TagsInput,
  TagsInputInputProps,
  TagsInputTagProps,
  usePromiseProcessing,
} from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';

import localization, { t } from 'localization';
import { axios, isAxiosError } from 'pipes/functions';
import { appStores } from 'store';

import { useAppContext } from 'components/AppContext';
import GroupedSelector, { OptionsGroup } from 'components/GroupedSelector';
import { useCaptcha } from 'components/Recaptcha/hook';
import { toOption } from 'helpers/toOption';
import UserService from 'service/UserService';
import { AccountsTabs } from 'views/AccountDetail';
import { Error } from 'views/BasePage';
import InnerPage from 'views/LayoutUi/InnerPage';

import { ResponseField } from './components/ResponseField';
import { inviteToProductLocalization } from './localization/inviteToProduct.loc';

import styles from './styles.module.scss';

localization.addTranslations(inviteToProductLocalization);

const accountsadminService = new AccountsadminService(axios);
const userService = new UserService();

const fieldOfError = ['failedInvalidEmails', 'failedMaxUsersReached', 'failedRegistrationDisabled'];
const fieldOfDups = ['failedAlreadyInvited', 'failedAlreadyMembersOfAccount'];
const emailRegexp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

const keysObject = {
  TAB: 9,
  ENTER: 13,
  SPACE: 32,
};

export const InviteToProductForm = () => {
  const history = useHistory();
  const pageParams = useParams<{ productDomain: string; accountId: string }>();
  const { appConfig, addAlert } = useAppContext();
  const { groupsAndRoles, getGroupAndRoles, currentUser } = {
    ...appStores.Accounts(state => ({
      groupsAndRoles: state.groupsAndRoles,
      getGroupAndRoles: state.getGroupAndRoles,
    })),
    ...appStores.CurrentUser(store => ({
      currentUser: store.user,
    })),
  };
  const captcha = useCaptcha(appConfig?.captcha);
  const [accountUsersCapacity, accountUsersCapacityApi] = usePromiseProcessing(
    accountsadminService.getAccountUsersCapacity
  );

  const [emails, setEmails] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [responseResult, setResponseResult] = useState<InviteUsersResponse>({} as InviteUsersResponse);
  const [validationError, setValidationError] = useState<string[] | null>(null);
  const [backendError, setBackendError] = useState<Error[]>([]);

  useEffect(() => {
    if (!groupsAndRoles.length) {
      getGroupAndRoles(parseInt(pageParams.accountId));
    }
    accountUsersCapacityApi(parseInt(pageParams.accountId));
  }, [accountUsersCapacityApi, getGroupAndRoles, groupsAndRoles.length, pageParams.accountId]);

  const maxUsersPerInvitation = appConfig?.invitationOptions?.maxUsersPerInvitation;

  const goBack = useCallback(
    () => history.push(`/c/accounts/${pageParams.accountId}?activeTab=${AccountsTabs.INVITES}`),
    [history, pageParams.accountId]
  );

  const handleEmailsChange = useCallback(
    (emails: string[]) => {
      validationError && setValidationError(null);
      setEmails(emails);
    },
    [validationError]
  );

  const groupsToOptions: OptionsGroup[] = useMemo(
    () =>
      groupsAndRoles.map(group => ({
        label: group.name,
        value: group.name,
        list: group.roles.map(role => toOption(role.name)),
      })),
    [groupsAndRoles]
  );

  const getAlertMessage = useCallback((emails: string[]) => {
    if (emails.length === 0) return t('InviteToProductForm:Alert:Message:Empty');
    return (
      <>
        <p className='mb-0'>{t('InviteToProductForm:Alert:Message')}</p>
        <ul className='mb-0 pl-5'>
          {emails.map(email => (
            <li key={email}>{email}</li>
          ))}
        </ul>
      </>
    );
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      let gRecaptchaResponse = '';

      if (appConfig?.captcha?.enabled && currentUser?.userId) {
        const { data } = await userService.checkIsCaptchaNeeded(currentUser?.userId, 'invite-into-account');
        if (data) gRecaptchaResponse = await captcha.executeCaptcha();
      }

      const response = await accountsadminService.inviteUser(
        parseInt(pageParams.accountId),
        { emails, roles, domain: pageParams.productDomain },
        gRecaptchaResponse
      );

      if (!response) return;

      addAlert({
        type: 'info',
        title: t('InviteToProductForm:Alert:Title'),
        time: Date.now(),
        message: 'successfullyInvited',
        messageComponent: () => getAlertMessage(response.successfullyInvited),
        showed: true,
        modalBodyComponent: () => getAlertMessage(response.successfullyInvited),
      });

      const newEmails = emails.filter(email => !response.successfullyInvited.includes(email));
      setEmails(newEmails);
      setResponseResult(response);
      captcha.resetCaptcha();
    } catch (error) {
      captcha.resetCaptcha();
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];
        setBackendError(errors);
        AppLogger.error({
          message: 'Error happens while trying to invite',
          exception: error,
        });
      }
    }
  }, [
    addAlert,
    appConfig?.captcha?.enabled,
    captcha,
    currentUser?.userId,
    emails,
    getAlertMessage,
    pageParams.accountId,
    pageParams.productDomain,
    roles,
  ]);

  const handleValidationReject = useCallback(emailsWithError => {
    setValidationError(emailsWithError);
  }, []);

  const getDomainString = useCallback(() => {
    if (!appConfig?.domains) return;
    const currentDomain = Object.values(appConfig?.domains).find(
      value => value.domain === pageParams.productDomain && value.invitationAvailable
    );

    return currentDomain?.appTitle ? `${currentDomain.appTitle} (${currentDomain.domain})` : currentDomain?.domain;
  }, [appConfig?.domains, pageParams.productDomain]);

  const getEmails = useCallback(
    (type: 'dups' | 'error') => {
      return Object.entries(responseResult)
        .filter(([key, _]) => (type === 'error' ? fieldOfError : fieldOfDups).includes(key))
        .map(([_, value]) => value)
        .flat();
    },
    [responseResult]
  );

  const preselectedGroup = useMemo(
    () =>
      groupsAndRoles.find(
        item =>
          item.associatedProduct ===
          (appConfig?.domains &&
            Object.values(appConfig?.domains).find(domain => {
              return domain.domain === pageParams.productDomain && domain.invitationAvailable;
            })?.product)
      )?.name || '',
    [appConfig?.domains, groupsAndRoles, pageParams.productDomain]
  );

  const pasteSplit = useCallback((data: string) => {
    return data
      .split(/[\s;,]/gm)
      .map(email => email.trim())
      .filter(Boolean);
  }, []);

  const failedEmails = useMemo(() => getEmails('error'), [getEmails]);
  const alreadyInvited = useMemo(() => getEmails('dups'), [getEmails]);

  if (accountUsersCapacity.loading) return <Spinner size='4x' />;
  if (!maxUsersPerInvitation || !accountUsersCapacity.result) return null;

  const { maxUsers, currentUsers } = accountUsersCapacity.result;
  const remainingUsersToInvite = maxUsers - currentUsers;

  const displayedMaxUsers =
    remainingUsersToInvite < maxUsersPerInvitation ? remainingUsersToInvite : maxUsersPerInvitation;

  return (
    <InnerPage
      title={
        <div className='flex-column ai-start gap-3x'>
          <div
            className='flex-row font-color-secondary cursor-pointer gap-4'
            onClick={goBack}
            data-test-id='InviteToProductForm.goBackButton'
          >
            <Icon size='sm' name='farArrowLeft' className='flex-row' />
            <p className='mb-0 font-size-14 line-height-20'>{t('InviteToProductForm:Back')}</p>
          </div>
          <div className='flex-column  gap-2x'>
            <h1 className='flex-row gap-8'>{t('InviteToProductForm:Header')}</h1>
          </div>
        </div>
      }
      className={styles.InviteToProductForm__wrapper}
    >
      <div className={styles.InviteToProductForm}>
        <div className={styles.InviteToProductForm__info}>
          <p className='mb-0'>{t('InviteToProductForm:ChoosedProduct')}</p>
          <p className='mb-0'>{getDomainString()}</p>
        </div>

        <div className={styles.InviteToProductForm__docs}>
          <a className='mb-o' target='_blank' href={t('InviteToProductForm:DocsLink:Link')} rel='noreferrer'>
            {t('InviteToProductForm:DocsLink:Text')}
          </a>
          <Icon name='farExternalLink' color='primary' />
        </div>

        <div className={styles.InviteToProductForm__roles}>
          <Label>{t('InviteToProductForm:Field:Roles')}</Label>
          <GroupedSelector
            groups={groupsToOptions}
            selected={roles}
            onChange={setRoles}
            preselectedGroup={preselectedGroup}
          />
        </div>

        <div className={styles.InviteToProductForm__users}>
          <Label>{t('InviteToProductForm:Field:Users')} </Label>
          <TagsInput
            value={emails}
            renderTag={DefaultRenderTag}
            onChange={handleEmailsChange}
            maxTags={displayedMaxUsers}
            className={styles.InviteToProductForm__emails}
            addKeys={[keysObject.ENTER, keysObject.SPACE, keysObject.TAB]}
            inputProps={{
              placeholder: '',
              className: cn({
                [styles.InviteToProductForm__emails__input__error]: validationError,
              }),
              setValidationError,
            }}
            tagProps={{ failedEmails, alreadyInvited }}
            onlyUnique
            validationRegex={emailRegexp}
            onValidationReject={handleValidationReject}
            renderInput={DefaultRenderInput}
            addOnPaste
            pasteSplit={pasteSplit}
            addOnBlur
          />
          <div className={styles.InviteToProductForm__limit}>
            <div>
              {backendError.length > 0 &&
                backendError.map(
                  error =>
                    localization.checkExistKey(`InviteToProductForm:Error:${error.error}`) && (
                      <small key={error.error} className='font-color-danger'>
                        {t(`InviteToProductForm:Error:${error.error}`)}
                      </small>
                    )
                )}
              {validationError?.length && (
                <small className='font-color-danger' data-test-id='InviteToProductForm.Error.Validation'>
                  {t('InviteToProductForm:Error:Validation', { emailsWithError: validationError.join(', ') })}
                </small>
              )}
              <small
                dangerouslySetInnerHTML={{
                  __html: t('InviteToProductForm:Field:Users:Info:Invite', {
                    link: t('InviteToProductForm:DocsLink:Invite:Link'),
                  }),
                }}
              ></small>
            </div>
            <small
              className={cn({ 'font-color-danger': emails.length >= displayedMaxUsers })}
              data-test-id='InviteToProductForm.Emails.Length'
            >{`${emails.length}/${displayedMaxUsers < 0 ? 0 : displayedMaxUsers}`}</small>
          </div>
        </div>
        <ResponseField responseFields={responseResult} />
        <Button
          className={styles.InviteToProductForm__button}
          color='primary'
          disabled={!emails.length || !roles.length}
          onClick={handleSubmit}
          data-test-id='InviteToProductForm.Submit'
        >
          {t('InviteToProductForm:Submit')}
        </Button>
      </div>
      {captcha.node}
    </InnerPage>
  );
};

function DefaultRenderTag(props: TagsInputTagProps) {
  const {
    tag,
    key,
    disabled,
    onRemove,
    classNameRemove,
    getTagDisplayValue,
    failedEmails,
    alreadyInvited,
    className,
    ...other
  } = props;

  const emailWithError = failedEmails.includes(tag);
  const emailInvited = alreadyInvited.includes(tag);
  return (
    <span
      key={key}
      className={cn(className, {
        [styles.InviteToProductForm__emails__error]: emailWithError,
        [styles.InviteToProductForm__emails__dups]: emailInvited,
      })}
      data-test-id={`InviteToProductForm.Tag.${tag}`}
      {...other}
    >
      {getTagDisplayValue(tag)}
      {!disabled && (
        <Icon
          name='falTimes'
          size='sm'
          onClick={e => onRemove(key)}
          data-test-id={`InviteToProductForm.Tag.Delete.${tag}`}
        />
      )}
    </span>
  );
}

function DefaultRenderInput(props: TagsInputInputProps) {
  const { onChange, value, addTag, setValidationError, ...other } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValidationError(false);
    onChange(event);
  };

  return (
    <input type='text' onChange={handleChange} value={value} {...other} data-test-id='InviteToProductForm.EmailInput' />
  );
}
