import { createRef, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { history } from 'App';
import classNames from 'classnames';
import { Button, InputText } from '@just-ai/just-ui';

import localize, { t } from 'localization';
import { isAxiosError } from 'pipes/functions';

import LoginService from 'service/LoginService';
import { BasePage, Error } from 'views/BasePage';

import { externalSsoLocalization } from './localization/externalSso.loc';

localize.addTranslations(externalSsoLocalization);

class State {
  errors: Error[] | [] = [];
  fetching: boolean = false;
  loaded: boolean = true;
}

const loginServiceInstance = new LoginService();

export default class ExternalSso extends BasePage<any, State> {
  name = 'ExternalSso';

  email = createRef<HTMLInputElement>();

  state = new State();

  componentDidMount() {
    const load = async () => {
      try {
        this.setState({ fetching: true });
        await loginServiceInstance.checkIsUserAuthorized();
        window.location.href = '/';
      } catch (e) {
        if (isAxiosError(e) && e.response?.data.error === 'accountsadmin.email.verification.not.verified') {
          history.push('/c/verify-email');
        }
      } finally {
        this.setState({ fetching: false });
      }
    };

    load();
  }

  submit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!this.email) {
      const commonErrors: Error[] = this.state.errors.filter((error: Error) => !error?.args?.path);
      this.setState({ errors: [...commonErrors, { args: { path: 'email' } }] });
    }

    this.setState({
      fetching: true,
    });

    try {
      this.externalSsoLogin(this.email.current?.value || '');
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];

        this.setState({
          errors: errors,
        });
      }
    } finally {
      this.setState({
        fetching: false,
      });
    }
  };

  renderHead = () => {
    return (
      <div className='base-page_formarea-head'>
        <h1 data-test-id='ExternalSsoPage.Title'>{t('Login: form header text')}</h1>
      </div>
    );
  };

  renderInputs = () => {
    return (
      <div className={classNames('form-row', { 'with-error': Boolean(this.renderFieldError('email')) })}>
        <label htmlFor='email'>{t(`Register: field email label`)}</label>
        <InputText
          name='email'
          id='email'
          innerRef={this.email}
          placeholder='user@example.com'
          autoFocus
          data-test-id='ExternalSsoPage.Input.Email'
        />
        {this.renderFieldError('email')}
      </div>
    );
  };

  renderButtons = () => {
    return (
      <div className='base-page_formarea-buttons login-buttons'>
        <Button color='primary' data-test-id='ExternalSsoPage.Submit'>
          {t('Register: submit button text')}
        </Button>
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div className='base-page_formarea-footer login'>
        <p>
          <NavLink to='/c/login' data-test-id='ExternalSsoPage.Login'>
            {t('ExternalSso:Footer:Email')}
          </NavLink>
        </p>
      </div>
    );
  };
}
