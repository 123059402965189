import React, { createRef, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { history } from 'App';
import classNames from 'classnames';
import queryString from 'query-string';
// @ts-ignore
import Cookies from 'universal-cookie';
import { RegistrationRequestDto } from '@just-ai/api/dist/generated/Accountsadmin';
import { Button, Checkbox, Icon, InputText } from '@just-ai/just-ui';
import { Select } from '@just-ai/just-ui/dist';
import { AppLogger } from '@just-ai/logger';

import { t } from 'localization';
import localize from 'localization';
import {
  getAvailableCountries,
  getDomainData,
  getTosAndPpLinksFromOptions,
  isAxiosError,
  sendAnalyticEvents,
} from 'pipes/functions';

import LoginService from 'service/LoginService';
import RegisterService from 'service/RegisterService';
import { utmKeeperService } from 'service/UtmKeeper';
import { BasePage, BasePropTypes, BaseStateTypes, Error } from 'views/BasePage';
import { github, google } from 'views/BasePage/icons';

import { registerLocalization } from './localization/register.loc';

const cookies = new Cookies();

localize.addTranslations(registerLocalization);

const REGISTER_FIELDS = ['name', 'email', 'password', 'countryIsoCode'];

interface RegisterState extends BaseStateTypes {
  showPassword: boolean;
  isTermsOfUseChecked: boolean;
  errors: Error[] | [];
  fetching: boolean;
  gRecaptchaResponse: any;
  countryIsoCode: string | null;
  isCountryAcceptOpen: boolean;
  isCountrySelectOpen?: boolean;
}

export default class Register extends BasePage<BasePropTypes, RegisterState> {
  name = 'Register';
  state = {
    showPassword: false,
    isTermsOfUseChecked: false,
    errors: [],
    fetching: false,
    gRecaptchaResponse: null,
    countryIsoCode: 'RU',
    isCountryAcceptOpen: true,
    isCountrySelectOpen: undefined,
    loaded: true,
  };

  form = {
    name: createRef<HTMLInputElement>(),
    email: createRef<HTMLInputElement>(),
    password: createRef<HTMLInputElement>(),
  };

  recaptchaInstance: any;

  RegisterService = new RegisterService();
  LoginService = new LoginService();

  componentDidMount() {
    const { appConfig } = this.context;
    if (!appConfig.registration.enabled) {
      history.push('/c/login');
    }
    utmKeeperService.setUtmMarks();
    this.supportAddOnMessageListener();
    this.detectCountryIsoCode();

    const load = async () => {
      try {
        this.setState({ fetching: true });
        await this.LoginService.checkIsUserAuthorized();
        window.location.href = '/';
      } catch (e) {
        if (isAxiosError(e) && e.response?.data.error === 'accountsadmin.email.verification.not.verified') {
          history.push('/c/verify-email');
        }
      } finally {
        this.setState({ fetching: false });
      }
    };

    const { search } = this.props.location;

    if (Boolean(search)) {
      const parsedLocationSearch = queryString.parse(search);
      const ref = parsedLocationSearch.ref as string;

      if (Boolean(ref) && ref.length > 0) {
        const refExpirationDate = new Date();
        refExpirationDate.setFullYear(refExpirationDate.getFullYear() + 1);
        cookies.set('alPartnerRef', ref, { path: '/', expires: refExpirationDate });
      }
    }

    load();
    const provider = this.checkDomainForDirectSso();
    if (provider) {
      this.loginWithProvider(provider);
    }
  }

  componentWillUnmount() {
    this.captchaReadyInterval && clearInterval(this.captchaReadyInterval);
  }

  detectCountryIsoCode = async () => {
    const { appConfig } = this.context;
    const registrationCountriesOptions = appConfig?.registration?.countryIsoCodes;
    try {
      const data = await this.RegisterService.detectCountryIsoCode();
      let detectedCountry = data.data;
      if (!detectedCountry && !registrationCountriesOptions) return;
      if (registrationCountriesOptions && !registrationCountriesOptions?.allowed.includes(detectedCountry)) {
        detectedCountry = registrationCountriesOptions?.default;
      }
      if (detectedCountry) {
        localStorage.CLOUD_COUNTRY_CODE = data.data;
      }
      this.setState({
        countryIsoCode: detectedCountry,
        isCountryAcceptOpen: Boolean(detectedCountry),
      });
    } catch (e) {
      if (isAxiosError(e)) {
        AppLogger.error({
          message: 'Failed to detect user country iso code',
          exception: e,
        });
      }
    }
  };

  validate = (registerObject: RegistrationRequestDto) => {
    const { isTermsOfUseChecked, errors } = this.state;

    const commonErrors: Error[] = errors.filter((error: Error) => !error?.args?.path);

    let fieldErrors: { args: { path: string } }[] = [];
    REGISTER_FIELDS.forEach((field: string) => {
      if (!registerObject[field as keyof RegistrationRequestDto]) {
        fieldErrors.push({
          args: {
            path: field,
          },
        });
      }
    });
    if (!isTermsOfUseChecked) {
      fieldErrors.push({
        args: { path: 'termsOfUse' },
      });
    }

    this.setState({ errors: [...commonErrors, ...fieldErrors] });
    return fieldErrors.length === 0;
  };

  submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { language, appConfig } = this.context;
    const { name, email, password } = this.form;
    const { countryIsoCode } = this.state;
    const {
      location: { search },
    } = this.props;

    const { product, redirectUrl } = getDomainData(search, appConfig?.domains);

    let ref = cookies.get('alPartnerRef') ?? '';
    if (Boolean(search)) {
      const parsedLocationSearch = queryString.parse(search.replace('?', ''));
      ref = parsedLocationSearch.ref as string;
    }

    const registerObject: RegistrationRequestDto = {
      name: name.current?.value?.trim() || '',
      email: email.current?.value?.trim() || '',
      password: password.current?.value?.trim() || '',
      countryIsoCode: countryIsoCode,
      language: language.substr(0, 2).toUpperCase(),
      redirectUrl: redirectUrl as string,
      utmParameters: utmKeeperService.getUtmMarks(),
    };

    if (ref) registerObject.referralToken = ref;

    if (product) registerObject.product = product;

    if (!this.validate(registerObject)) return;

    this.setState({
      fetching: true,
    });

    if (appConfig?.captcha?.enabled) {
      await this.executeCaptcha();
    }

    try {
      const { data } = await this.RegisterService.register(registerObject, this.state.gRecaptchaResponse);
      cookies.remove('alPartnerRef', { path: '/' });
      utmKeeperService.clearUtmMarks();
      sendAnalyticEvents(
        {
          event: 'GAEvent',
          eventAction: 'registration success',
          eventCategory: 'registration',
          options: { userId: data.userData.userId },
        },
        {
          event: 'reachGoal',
          eventType: 'registration',
        },
        { eventType: 'registration' },
        { event: 'track', eventType: 'CompleteRegistration' }
      );
      this.setState({
        fetching: false,
      });
      history.push(`/c/verify-email?redirectUrl=${registerObject.redirectUrl}`);
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];
        this.resetCaptcha();
        this.setState({
          errors: errors,
          fetching: false,
        });
      }
    }
  };

  togglePasswordShow = () => this.setState({ showPassword: !this.state.showPassword });

  toggleTermsOfUseLink = () => this.setState({ isTermsOfUseChecked: !this.state.isTermsOfUseChecked });

  changeCountry = (country: any) => {
    this.setState({ countryIsoCode: country.value, isCountrySelectOpen: undefined });
  };

  handleAcceptOrRejectChoosenCountry = (flag: boolean) => () => {
    if (!flag) {
      this.setState({
        countryIsoCode: null,
      });
    }
    this.setState({
      isCountryAcceptOpen: false,
      isCountrySelectOpen: !flag || undefined,
    });
  };

  renderInputs = () => {
    const { showPassword, isTermsOfUseChecked, errors, countryIsoCode } = this.state;
    const termsOfUseError: Error | undefined = errors.find((error: Error) => error.args?.path === 'termsOfUse');
    const {
      appConfig: { domains },
    } = this.context;

    const { termsOfUseUrl, privacyPolicyUrl } = getTosAndPpLinksFromOptions(countryIsoCode, domains);

    return (
      <>
        <div className={classNames('form-row', { 'with-error': Boolean(this.renderFieldError('name')) })}>
          <label htmlFor='name'>{t(`Register: field name label`)}</label>
          <InputText
            name='name'
            id='name'
            innerRef={this.form.name}
            placeholder={t(`Register: field name label`)}
            maxLength={200}
            data-test-id='RegisterPage.Input.Name'
          />
          {this.renderFieldError('name')}
        </div>
        <div className={classNames('form-row', { 'with-error': Boolean(this.renderFieldError('email')) })}>
          <label htmlFor='email'>{t(`Register: field email label`)}</label>
          <InputText
            name='email'
            id='email'
            innerRef={this.form.email}
            placeholder='user@example.com'
            data-test-id='RegisterPage.Input.Email'
          />
          {this.renderFieldError('email')}
        </div>
        <div
          className={classNames('form-row password-row', {
            'with-error': Boolean(this.renderFieldError('password')),
          })}
        >
          <label htmlFor='password'>{t(`Register: field password label`)}</label>
          <InputText
            type={showPassword ? 'text' : 'password'}
            name='password'
            id='password'
            innerRef={this.form.password}
            data-test-id='RegisterPage.Input.Password'
          />
          <Icon
            name={showPassword ? 'faEyeSlash' : 'faEye'}
            size='lg'
            onClick={this.togglePasswordShow}
            data-test-id='RegisterPage.Input.Password.Show'
          />
          {this.renderFieldError('password')}
        </div>
        <div className='form-row'>
          <label>
            <Checkbox
              name='checkbox'
              onChange={this.toggleTermsOfUseLink}
              invalid={Boolean(termsOfUseError)}
              value={isTermsOfUseChecked}
              label={
                <span>
                  {t('Register: I agree with')}
                  <a
                    href={termsOfUseUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    data-test-id='RegisterPage.Link.Tos'
                  >
                    {t('Register: ToS link text')}
                  </a>{' '}
                  {t('and')}{' '}
                  <a
                    href={privacyPolicyUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    data-test-id='RegisterPage.Link.Policy'
                  >
                    {t('Register: policy link text')}
                  </a>
                </span>
              }
              data-test-id='RegisterPage.Input.TermsOfUse'
            />
          </label>
        </div>
      </>
    );
  };

  renderButtons = () => {
    const { domainOptions, appConfig } = this.context;

    const notPartOfCC = !domainOptions?.partOfConversationalCloud;
    return (
      <div className='base-page_formarea-buttons'>
        <Button color='primary' data-test-id='RegisterPage.Submit'>
          {t('Register: submit button text')}
        </Button>
        <div className='to-other-login'>
          {appConfig.authorization.socialLoginEnabled && (
            <>
              <p>{t('Register: register with')}</p>
              <div className={classNames('to-other-login-buttons', { 'not-part-of-cc': notPartOfCC })}>
                <Button
                  color='secondary'
                  outline
                  onClick={() => this.loginWithProvider('google')}
                  data-test-id='RegisterPage.ToOtherLogin.Google'
                >
                  {google} {t('Login: login with google')}
                </Button>
                {!notPartOfCC && (
                  <Button
                    color='secondary'
                    outline
                    onClick={() => this.loginWithProvider('github')}
                    data-test-id='RegisterPage.ToOtherLogin.Github'
                  >
                    {github} {t('Login: login with github')}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  renderHead = () => {
    const { countryIsoCode, isCountryAcceptOpen, isCountrySelectOpen } = this.state;
    const { language, appConfig } = this.context;

    const availableCountriesList = getAvailableCountries(
      language.substr(0, 2).toUpperCase(),
      appConfig?.registration?.countryIsoCodes
    );

    const isUserCountryAvailable = availableCountriesList.find(option => option.value === countryIsoCode);

    const selected = availableCountriesList.find(
      (option: { value: string; label: string }) =>
        option.value === (isUserCountryAvailable?.value || appConfig?.registration?.countryIsoCode?.default)
    );

    return (
      <div className='base-page_formarea-head register-form-head'>
        <h1 data-test-id='RegisterPage.Title'>{t('Register: form header text')}</h1>
        <div className='countrySelect-wrap'>
          <Select
            className='countrySelect'
            classNamePrefix='countrySelect-prefix'
            options={availableCountriesList}
            onChange={this.changeCountry}
            value={selected}
            menuIsOpen={isCountrySelectOpen}
            placeholder={t('Register: select country placeholder')}
            data-test-id='RegisterPage.CountrySelect'
          />
          {this.renderFieldError('countryIsoCode')}
        </div>
        {isCountryAcceptOpen && (
          <div className='countrySelect-accept' data-test-id='RegisterPage.CountryAccept'>
            <small>{t('Register: accept text')}</small>
            <div>
              <small
                onClick={this.handleAcceptOrRejectChoosenCountry(true)}
                data-test-id='RegisterPage.CountryAccept.Accept'
              >
                {t('Register: accept yes')}
              </small>
              <small
                onClick={this.handleAcceptOrRejectChoosenCountry(false)}
                data-test-id='RegisterPage.CountryAccept.Decline'
              >
                {t('Register: accept no')}
              </small>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderFooter = () => {
    const { appConfig } = this.context;
    const { location } = this.props;

    return (
      <div className='base-page_formarea-footer register'>
        <p>
          {t('Register: have account')}
          <NavLink to={`/c/login${location?.search}`} data-test-id='RegisterPage.Login'>
            {t('Register: login link')}
          </NavLink>
        </p>
        {appConfig.authorization.externalSsoEnabled && (
          <p>
            <NavLink to='/c/external-sso' data-test-id='RegisterPage.ExternalSso'>
              {t('Login: externalSso')}
            </NavLink>
          </p>
        )}
      </div>
    );
  };
}
